<template>
  <KpiDireksiDekomForm mode="Ubah" module="KPI Direksi Dekom"> </KpiDireksiDekomForm>
</template>

<script>
import KpiDireksiDekomForm from './form';

const KpiDireksiDekomUpdate = {
  name: 'KpiDireksiDekomUpdate',
  components: { KpiDireksiDekomForm },
};

export default KpiDireksiDekomUpdate;
</script>
